export const data = {
  
    items:[
        {
            id:1,
            partner:"",
            title_hu:"OEE - teljes eszközhatékonyság",
            title_en:"OEE - Overall Equipment Effectiveness",
            detail_hu:"Az OEE segítségével maximalizálhatja gépei hatékonyságát, csökkentheti az állásidőket és optimalizálhatja a termelési folyamatokat. Ha valódi versenyelőnyre vágyik a gyártásban, az OEE program a kulcs a sikerhez!",
            detail_en:"With the help of OEE, you can maximize the efficiency of your machines, reduce downtimes, and optimize production processes. If you're looking for a real competitive advantage in manufacturing, the OEE program is the key to success!",
            type_hu:"TÖBBNYELVŰ WEBALKALMAZÁS",
            type_en:"MULTILANGUAGE WEB APPLICATION",
            type:"APP",
            img:require("../img/works/1.jpg"),
            technology:".NET",
            isActive:false,
            isVisible: false,
        },
        {
            id:2,
            partner:"",
            title_hu:"SPC - Statisztikai folyamatszabályozás",
            title_en:"SPC - Statistical process control",
            detail_hu:"Bemutatom SPC alkalmazásomat (Statisztikai folyamatszabályozó program), ami forradalmasítja a minőségellenőrzést és a folyamatoptimalizálást. Az SPC segítségével könnyedén monitorozhatja és szabályozhatja a termelési folyamatokat valós időben, minimalizálva a hibák és selejtek számát, így növelve a hatékonyságot és a termékminőséget. Hozza ki a legtöbbet a gyártásából az SPC segítségével!",
            detail_en:"I introduce my SPC application (Statistical Process Control program), which revolutionizes quality control and process optimization. With SPC, you can effortlessly monitor and control production processes in real-time, minimizing errors and defects, thereby increasing efficiency and product quality. Get the most out of your manufacturing with SPC!",
            type_hu:"TÖBBNYELVŰ WEBALKALMAZÁS",
            type_en:"MULTILANGUAGE WEB APPLICATION",
            img:require("../img/works/2.jpg"),
            type:"APP",
            technology:".NET",
            isActive:false,
            isVisible: false,
        },
        {
            id:3,
            partner:"VivienPhoto",
            title_hu:"VivienPhoto",
            title_en:"VivienPhoto",
            detail_hu:"Ez a weboldal a kreativitás és technológia művészi összeolvadása, amely a fotózás világába varázsolja Önt. Az elegáns design és a felhasználóbarát felület együttműködése által könnyedén felfedezheti a portfóliót, és mélyen belemélyedhet a képek történeteibe",
            detail_en:"This website is the artistic fusion of creativity and technology, immersing you into the world of photography. Through the collaboration of elegant design and user-friendly interface, you can effortlessly explore the portfolio and delve deep into the stories behind the images.",
            type_hu:"TÖBBNYELVŰ WEBOLDAL",
            type_en:"MULTILANGUAGE WEBSITE",
            img:require("../img/works/3.jpg"),
            type:"PAGE",
            technology:".NET | ANGULAR",
            isActive:false,
            isVisible: false,
        },
        {
            id:4,
            partner:"Hechinger Hungary Kft",
            title_hu:"Hechinger FSK - Hibagyűjtő és kiértékelő rendszer",
            title_en:"Hechinger FSK - Error collection and assessment system",
            detail_hu:"Azt hisszük, hogy minden hiba rejt magában lehetőséget a fejlődésre. Ezért hoztuk létre ezt a kifinomult szoftvert, amely lehetővé teszi, hogy a hibákat ne csak problémaként lássuk, hanem a folyamatos javulás és hatékonyság növelés lehetőségének előfutáraként.Ez az alkalmazás lehetővé teszi, hogy a hibák adatait gyorsan és hatékonyan kezelje, és egyúttal részletes statisztikákat, interaktív grafikonokat és informatív táblázatokat generáljon a hibaelőfordulásokról. Legyen a döntések készítése mostantól adatvezérelt és pontos, amelyek a termelési hatékonyság növelését célozzák.A .Net Core és Angular technológiák összjátéka garantálja a stabil és biztonságos működést, miközben a felhasználóbarát felület révén könnyedén és gyorsan navigálhat az adatok között. Bármikor és bárhonnan elérheti az alkalmazást, így mindig képben lehet a gyártási folyamatokkal.",
            detail_en:"We believe that every error holds the potential for improvement. That's why we've created this sophisticated software that allows us to see errors not just as problems but as harbingers of continuous enhancement and increased efficiency.This application enables you to manage error data quickly and efficiently, while also generating detailed statistics, interactive graphs, and informative tables on error occurrences. Make data-driven and precise decisions aimed at improving production efficiency from now on.The synergy of .Net Core and Angular technologies ensures stable and secure operation, while the user-friendly interface allows for easy and swift navigation through the data. You can access the application anytime and anywhere, keeping you informed about production processes at all times.",
            type_hu:"TÖBBNYELVŰ WEBALKALMAZÁS",
            type_en:"MULTILANGUAGE WEB APPLICATION",
            img:require("../img/works/4.jpg"),
            type:"APP",
            technology:".NET | ANGULAR",
            isActive:false,
            isVisible: false,
        },
        {
            id:5,
            partner:"Pesti Rajmund",
            title_hu:"PRIT.HU",
            title_en:"PRIT.HU",
            detail_hu:"Explore the exclusive sysadmin/IT professional portfolio website where the genius behind digital systems and IT solutions is unveiled.",
            detail_en:"Explore the exclusive sysadmin/IT professional portfolio website where the genius behind digital systems and IT solutions is unveiled.",
            type_hu:"WEBOLDAL",
            type_en:"WEBSITE",
            img:require("../img/works/5.jpg"),
            type:"PAGE",
            technology:"HTML | CSS | JAVASCRIPT",
            isActive:false,
            isVisible: false,
        },
        {
            id:6,
            partner:"Holecz Péter",
            title_hu:"HOLECZPETER.COM",
            title_en:"HOLECZPETER.COM",
            detail_hu:"Üdvözlöm a személyes portfóliómban! A weboldalam Vue.js technológiával lett létrehozva, így garantáltan modern és interaktív élményt nyújt. Fedezze fel a projekteket, amelyeken dolgoztam, és ismerje meg a különböző készségeimet. Ha szeretne egyedi és hatásos megoldásokat látni, amelyekkel inspirálódhat, akkor a megfelelő helyen jár. Köszönöm, hogy meglátogatta az oldalamat. Egyedi szoftverfejlesztési megoldások miatt lépjen kapcsolatba velem",
            detail_en:"Welcome to my personal portfolio! My website is built using Vue.js technology, ensuring a modern and interactive experience. Explore the projects I've worked on and discover my various skills. If you're looking for unique and effective solutions to inspire you, you're in the right place. Thank you for visiting my site. Contact me for custom software development solutions.",
            type_hu:"TÖBBNYELVŰ WEBOLDAL",
            type_en:"MULTILANGUAGE WEBSITE",
            img:require("../img/works/6.jpg"),
            type:"PAGE",
            technology:"VUE.JS | HTML | CSS",
            isActive:false,
            isVisible: false,
        },
        {
            id:7,
            partner:"Cable Solutions Kft.",
            title_hu:"CABLESOLUTIONS.EU",
            title_en:"CABLESOLUTIONS.EU",
            detail_hu:"A Cable Solutions Kft számára én készítettem a weboldalt, a tervezéstől a kivitelezésig, biztosítva, hogy az ügyfeleknek intuitív és információval teli felhasználói élményben legyen részük. Cable Solutions 2011 óta van jelen az elektronikai termékek piacán, mint kábel-, vezeték-, és vezérlőszekrények egyik vezető összeszerelő vállalata, holland ügyvezetéssel, magyarországi gyártó telephellyel.",
            detail_en:"I designed the website for Cable Solutions Ltd from conception to execution, ensuring customers experience an intuitive and informative user interface.Cable Solutions has been present on the electronic products market since 2011 as a company assembling cable, wire and electronic control boxes, with Dutch management and production site in Hungary",
            type_hu:"TÖBBNYELVŰ WEBOLDAL",
            type_en:"MULTILANGUAGE WEBSITE",
            img:require("../img/works/7.jpg"),
            type:"PAGE",
            technology:"VUE.JS | HTML | CSS",
            isActive:false,
            isVisible: false,
        },
        {
            id:8,
            partner:"Best-Log Kft.",
            title_hu:"BESTLOG.HU",
            title_en:"BESTLOG.HU",
            detail_hu:"A Best-Log Kft. weboldalát én terveztem és készítettem, kezdve a tervezéstől egészen a megvalósításig, hogy ügyfeleik könnyen eligazodjanak és teljes körű információt kapjanak szállítmányozási szolgáltatásaikról",
            detail_en:"I designed and developed the website for Best-Log Ltd., from planning to execution, ensuring their clients can easily navigate and obtain comprehensive information about their shipping services.",
            type_hu:"WEBOLDAL",
            type_en:"WEBSITE",
            img:require("../img/works/8.jpg"),
            type:"PAGE",
            technology:"VUE.JS | HTML | CSS",
            isActive:false,
            isVisible: false,
        },
    ]
}