<template>
  <div class="news_list">
    <ul>
     <li  v-for="(work, key) in works" :key="key">
        <div class="list_inner" v-scroll-reveal="{ delay: 100 }">
          <div class="image">
            <img :src="work.img" alt="thumb" />
            <div class="main" @click="showModal(work.id)" :style="{ backgroundImage: 'url(' + work.img + ')' }">
            </div>
          </div>
          <div class="details">
            <h3  @click="showModal(work.id)" class="title">{{ $i18n.locale == 'en' ? work.title_en: work.title_hu}} </h3>
            <p class="date">
              <a  href="#">{{$i18n.locale == 'en' ?  work.type_en : work.type_hu}}</a> 
            </p>
            <p class="date">
              {{work.technology}}
            </p>
          </div>
        </div>
        <transition name="fade">
          <div
            :class="{ 'modal-mask': isActive(work.id) }"
            id="modal"
            @click="closeModal(work.id)"
            v-if="isVisible(work.id)">
            <div class="modal-dialog modal-dialog-centered " @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal(work.id)">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"/>
                </div>
                <div class="shane_tm_modalbox_news">
                  <div class="box_inner">
                    <div class="image">
                      <img :src="work.img" alt="thumb"/>
                      <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + work.img + ')' }">
                      </div>
                    </div>
                    <div class="details">
                      <h3 class="title">
                        {{ $i18n.locale == 'en' ? work.title_en: work.title_hu}}
                      </h3>
                      <p class="date" >
                        <a href="#">{{ $i18n.locale == 'en' ? work.type_en : work.type_hu}}</a>
                      </p>
                    </div>
                    <div class="description">
                      <p class="justify-p">
                        {{ $i18n.locale == 'en' ? work.detail_en : work.detail_hu}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </li>
    
    </ul>
  </div>
</template>

<script>
import { data } from '@/assets/js/works.js'; 
export default {
  components: {
  },
  data() {
    return {
      works: data.items,
     
    };
  },
  methods: {
    showModal: function(id) {
      const currentWork = this.works.find(x=> x.id == id);
      if(currentWork){ 
        currentWork.isActive  = true;
        currentWork.isVisible = true;
      }
    },
    closeModal: function(id) {
      const currentWork = this.works.find(x=> x.id == id);
      if(currentWork){
        currentWork.isActive  = false;
        currentWork.isVisible = false;
      }
    },
    isActive: function(id){
      const currentWork = this.works.find(x=> x.id == id);
      return currentWork?.isActive;
    },
    isVisible: function(id){
      const currentWork = this.works.find(x=> x.id == id);
      return currentWork?.isVisible;
    }
  },
};
</script>

<style lang="scss" scoped></style>
