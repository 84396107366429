export const data = {
  
    items:[
        
            { 
                id:1,
                title: 'home', 
                routerlink: '#home' ,
                hasChildren: false
            },
            { 
                id:2,
                title: 'about', 
                routerlink: '#about',
            },
            { 
                id:3,
                title: 'works', 
                routerlink: '#portfolio',
                
            },
            { 
                id:4,
                title: 'portfolio', 
                routerlink: '#skills',
            },
            { 
                id:5,
                title: 'partners', 
                routerlink: '#brand',
                open:false,
                hasChildren: false
            },
            
            { 
                id:6,
                title: 'testimonial', 
                routerlink: '#testimonial',
                open:false,
                hasChildren: false
            },
            { 
                id:7,
                title: 'contact', 
                routerlink: '#contact',
                open:false,
                hasChildren: false
            },
          ],
}