<template>
   <div class="shane_tm_section site-footer">
      <div class="shane_tm_footer">
         <div class="container">
            <div class="footer_list">
               <ul>
                  <li>
                     <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="200ms">
                         <div class="logo">
                           <img class="logo-img2" src="../assets/img/logo/white.png" alt="partners brand" />
                        </div>
                        <ul class="footer-links">
                           <li>
                              <div class="list_inner">
                                 <img
                                    class="svg"
                                    src="../assets/img/svg/location.svg"
                                    alt="location"/>
                                 <p>6060, Tiszakécske Ady E. 79</p>
                              </div>
                           </li>
                           <li>
                              <div class="list_inner">
                                 <img
                                    class="svg"
                                    src="../assets/img/svg/phone.svg"
                                    alt="phone" />
                                 <p>
                                    <a href="tel:+36 30 555 83 93">+36 30 555 83 93</a>
                                 </p>
                              </div>
                           </li>
                           <li>
                              <div class="list_inner">
                                 <img
                                    class="svg"
                                    src="../assets/img/svg/email.svg"
                                    alt="phone" />
                                 <p> 
                                    <a href="mailto:info@holeczpeter.com">info@holeczpeter.com</a>
                                 </p>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </li>
                  <li>
                     <div class="col-xs-6 col-md-3 col-sm-6 col-12">
                        <h6>Quick Links</h6>
                        <ul class="footer-links">
                           <li v-for="(item, index) in menuitems"  :key="index" ><a :href="item.routerlink">{{ $t(item.title) }}</a></li>
                        </ul>
                     </div>
                  </li>
                  <li>
                     <div class="col-xs-6 col-md-3 col-sm-6 col-12">
                        <h6>Support</h6>
                        <ul class="footer-links">
                           <li><a href="#">Impresszum</a></li>
                           <li><a href="#">Adatvédelmi tájékoztató</a></li>
                           <li><a href="#contact">Contact</a></li>
                        </ul>
                     </div>
                  </li>
               </ul>
            </div>
            <hr>
            <div class="footer-container">
               <div class="row">
                  <div class="col-md-8 col-sm-6 col-xs-12">
                     <p class="copyright-text"> © 2024 Holecz Péter Minden jog fenntartva 
                        <a href="#">Holecz Péter</a>.
                     </p>
                  </div>
                  <div class="col-md-4 col-sm-6 col-xs-12">
                     <ul class="footer-social-icons">
                        <li v-for="(social, i) in socialList" :key="i">
                        <a :href="social.link" target="_blank" rel="noreferrer"
                           ><img class="svg invert" :src="social.src" alt="social"
                        /></a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import { data } from '@/assets/js/menu.js'; 

export default {
   name: 'Header',
   data() {
    return {
      menuitems: data.items,
      socialList: [
        {
          link: "https://www.facebook.com/holecz.peter1",
          src: require("../assets/img/svg/social/facebook.svg"),
        },
        {
          link: "https://www.linkedin.com/in/holecz-péter-90006b146/",
          src: require("../assets/img/svg/social/linkedin.svg"),
        },
        {
          link: "https://www.instagram.com/holeczpeter/",
          src: require("../assets/img/svg/social/instagram.svg"),
        },
      ],
    };
  },
};
</script>

