var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shane_tm_section"},[_c('div',{staticClass:"shane_tm_skills"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"skills_inner"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ delay: 50, distance: '50px', duration: 1000 }),expression:"{ delay: 50, distance: '50px', duration: 1000 }"}],staticClass:"left"},[_c('div',{staticClass:"shane_tm_title"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('skills_desc')))])]),_c('div',{staticClass:"text"},[_c('p',{staticClass:"justify-p"},[_vm._v(" "+_vm._s(_vm.$t('skills_details'))+" ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"tokyo_progress"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 50,
                distance: '20px',
                duration: 1000,
              }),expression:"{\n                delay: 50,\n                distance: '20px',\n                duration: 1000,\n              }"}],staticClass:"progress_inner"},[_c('span',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('web_pages')))]),_c('span',{staticClass:"number"},[_vm._v("20%")])]),_vm._m(0)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 100,
                distance: '20px',
                duration: 1200,
              }),expression:"{\n                delay: 100,\n                distance: '20px',\n                duration: 1200,\n              }"}],staticClass:"progress_inner"},[_c('span',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('web_apps')))]),_c('span',{staticClass:"number"},[_vm._v("70%")])]),_vm._m(1)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 150,
                distance: '20px',
                duration: 1300,
              }),expression:"{\n                delay: 150,\n                distance: '20px',\n                duration: 1300,\n              }"}],staticClass:"progress_inner"},[_c('span',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('desktop_apps')))]),_c('span',{staticClass:"number"},[_vm._v("10%")])]),_vm._m(2)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"20%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"70%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"10%"}})])])
}]

export { render, staticRenderFns }