<template>
  <div>
    <scroll-fixed-header :fixed.sync="fixed" :threshold="56">
      <div class="shane_tm_topbar">
        <div class="topbar_inner">
          <div class="logo">
           <img class="logo-img" src="../assets/img/logo/dark.png" alt="partners brand" />
          </div>
          <div class="menu">
            <scrollactive
              class="anchor_nav"
              bezier-easing-value=".5,0,.35,1"
              :offset="95"
              :duration="100"
              :alwaysTrack="true">
              <a v-for="(item, index) in menuitems" 
                              :key="index" 
                              :href="item.routerlink" 
                               class="scrollactive-item">
                               {{ $t(item.title) }}
              </a>
              <Language></Language><br/><br/>
            </scrollactive>
          </div>
          
        </div>
      </div>
    </scroll-fixed-header>
    <div class="shane_tm_mobile_menu">
      <div class="topbar_inner">
        <div class="container bigger">
          <div class="topbar_in">
            <div class="logo">
              <img
                  src="../assets/img/logo/dark.png"
                  alt="partners brand"/>
            </div>
            <div class="my_trigger">
              <div
                class="hamburger hamburger--collapse-r"
                :class="{ 'is-active': active }">
                <div
                  class="hamburger-box"
                  @click="active = !active"
                  :aria-pressed="active ? 'true' : 'false'">
                  <div class="hamburger-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown" :class="{ active: active }">
        <div class="container">
          <span
            class="close_menu"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'">
             {{ $t('close') }}
          </span>
          <div class="dropdown_inner">
            <ul class="anchor_nav">
              <li class="current" v-for="(item, index) in menuitems" 
                              :key="index" >
                <a :href="item.routerlink" @click="active = !active">
                  {{ $t(item.title) }}
                </a>
              </li>
              <li>
              <Language></Language><br/><br/>
              </li>
            </ul>
            <div class="social-menu">
              <Social />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Social from "../components/SocialTwo.vue";
import Language from "../components/language/Language.vue";
import { data } from '@/assets/js/menu.js'; 
export default {
  components: {
    Social,
    Language,
  },
  data() {
    return {
      menuitems: data.items,
      active: false,
      fixed: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
