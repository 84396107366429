<template>
  <div class="map_wrap">
   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d174320.4712749894!2d19.906533914779747!3d46.946807709615605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474158830f41082f%3A0x5ad346f5b0ebfca2!2sTiszak%C3%A9cske!5e0!3m2!1shu!2shu!4v1692713633739!5m2!1shu!2shu" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
